import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
const SOAP = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        cnai-template-id="SOAP"
        cnai-template-name="SOAP Note"
        cnai-template-type="Progress Note"
      >
        <Grid item xs={12}>
          <Typography variant="h4">SOAP Note</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Subjective</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter subjective information here"
            inputProps={{
              'cnai-template-field-id': 'subjective',
              'cnai-template-field-name': 'Subjective',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Objective</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter objective information here"
            inputProps={{
              'cnai-template-field-id': 'objective',
              'cnai-template-field-name': 'Objective',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Assessment</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter assessment information here"
            inputProps={{
              'cnai-template-field-id': 'assessment',
              'cnai-template-field-name': 'Assessment',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Plan</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter plan information here"
            inputProps={{
              'cnai-template-field-id': 'plan',
              'cnai-template-field-name': 'Plan',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Referral</Typography>
          <Checkbox
            inputProps={{
              // @ts-ignore
              'cnai-template-field-id': 'referral',
              'cnai-template-field-name': 'Is Referral?',
              'cnai-template-field-type': 'boolean',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained">Save</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SOAP
