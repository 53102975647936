import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import { useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'

type InitializeDemoDialogProps = {
  open: boolean
  onAgree: (templateEditing: boolean) => void
  onCancel: () => void
}

const InitializeDemoDialog = ({ open, onAgree, onCancel }: InitializeDemoDialogProps) => {
  const [templateEditing, setTemplateEditing] = useState(false)

  return (
    <Dialog open={open}>
      <DialogTitle>Create Test API Access Key</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          In order to display a proper demo, we will need to create a temporary API access key.
        </Typography>
        <Typography variant="body1">This key will be prefixed with "Demo Key"</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={templateEditing}
              onChange={() => setTemplateEditing(!templateEditing)}
            />
          }
          label="Enable Template Editing"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onAgree(templateEditing)}>Create Temporary Key</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InitializeDemoDialog
